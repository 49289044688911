/**
 *
 * Price
 *
 */

import React from 'react';
import styled from 'styled-components';
import Heading from 'Components/Heading';

export interface PriceProps {
  type?: 'h1' | 'h2';
  currency: string;
  price: string | number;
}

const Price = ({ type = 'h2', currency, price }: PriceProps) => (
  <Container>
    <CurrencyContainer>
      <FromLabel type={type}>DESDE</FromLabel>
      <CurrencyLabel type={type}>{currency}</CurrencyLabel>
    </CurrencyContainer>
    <Heading color="orange" type={type}>
      {price}
    </Heading>
  </Container>
);

const SizeByType = {
  h1: {
    label: `
    font-size: 8px;
    line-height: 10px;
    `,
    currency: `
    font-size: 14px;
    line-height: 17px;
    `
  },
  h2: {
    label: `
    font-size: 8px;
    line-height: 10px;
    `,
    currency: `
    font-size: 14px;
    line-height: 17px;
    `
  }
};

interface TypeProps {
  type: 'h1' | 'h2';
}

const FromLabel = styled.span<TypeProps>`
  font-family: ${({ theme }) => theme.text.fontFamily.default};
  font-weight: ${({ theme }) => theme.text.fontWeights.semibold};
  color: ${({ theme }) => theme.colors.orange};
  ${({ type }) => SizeByType[type].label}
`;

const CurrencyLabel = styled.span<TypeProps>`
  font-family: ${({ theme }) => theme.text.fontFamily.default};
  font-weight: ${({ theme }) => theme.text.fontWeights.semibold};
  ${({ type }) => SizeByType[type].currency}
  color: ${({ theme }) => theme.colors.orange};
`;

const Container = styled.div`
  display: flex;
  align-items: stretch;
`;

const CurrencyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export default Price;
