//Este banner no se está utilizando en este momento
import React from 'react';
import styled from 'styled-components';
import { useBreakpoints } from './useBreakpoints'

import Large from './Assets/lanzamiento_large.png';
import Medium from './Assets/lanzamiento_medium.png';
import Small from './Assets/lanzamiento_small.png';
import Xlarge from './Assets/lanzamiento_xlarge.png';
import Xsmall from './Assets/lanzamiento_xsmall.png';
import Xxlarge from './Assets/lanzamiento_xxlarge.png';

const StyledImg = styled.img`
  border-radius: 14px;
  cursor: pointer;
`;
const BannerContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 25px;
`;

const promoURL = {
  CO: 'https://mevuelo.com.co/promociones-en-paquetes-vacacionales?como=Promo%20Lanzamiento%20&origen=TODOS',
  BO: 'https://mevuelo.com.bo/promociones-en-paquetes-vacacionales?como=Promo%20Lanzamiento%20&origen=TODOS'
}

export const LanzamientoBanner = () => {
  const breakpoints = useBreakpoints();
  const images = {
    xsmall: Xsmall,
    small: Small,
    medium: Medium,
    large: Large,
    xlarge: Xlarge,
    xxlarge: Xxlarge
  };

  let imageSrc: string;
  if (breakpoints.xxlarge) {
    imageSrc = images.xxlarge;
  } else if (breakpoints.xlarge) {
    imageSrc = images.xlarge;
  } else if (breakpoints.large) {
    imageSrc = images.large;
  } else if (breakpoints.medium) {
    imageSrc = images.medium;
  } else if (breakpoints.small) {
    imageSrc = images.small;
  } else {
    imageSrc = images.xsmall;
  }

  const openPromo = () => {
    window.open(promoURL[process.env.REACT_APP_COUNTRY])
  }

  return <BannerContainer onClick={openPromo}><StyledImg src={imageSrc} alt="Promo aniversario" /></BannerContainer>;
};
