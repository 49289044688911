/**
 *
 * HomeMultiCountry
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import { Breakpoint } from 'react-socks';
import logo from '../../Assets/logo.svg';
import Text from '../Text';
import uruguay from '../../Assets/images/flagUY.png';
import bolivia from '../../Assets/images/flagBO.png';
import paraguay from '../../Assets/images/flagPY.png';
import colombia from '../../Assets/images/flagCO.png';
import mexico from '../../Assets/images/flagMX.png';
import chile from '../../Assets/images/flagCL.png';
import { Helmet } from 'react-helmet';
import backgroundMC from '../../Assets/images/backgroundMC.webp';
import { ogLanguage } from '../../Constants/index';

const HomeMultiCountry = ({ className }) => {
  const goTo = (country = 'uruguay') => () => {
    switch (country) {
      case 'paraguay':
        window.location.replace('https://mevuelo.com.py');
        break;

      case 'bolivia':
        window.location.replace('https://mevuelo.com.bo');
        break;

      case 'colombia':
        window.location.replace('https://mevuelo.com.co');
        break;

      case 'chile':
        window.location.replace('https://mevuelo.cl');
        break;

      case 'mexico':
        window.location.replace('https://mevuelo.mx');
        break;

      default:
        window.location.replace('https://mevuelo.com.uy');
        break;
    }
  };
  // TODO: CHANGE KEYWORDS, DESCRIPTION AND TITLE ACCORDING TO SEBA FEEDBACK
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          MeVuelo | Promociones en paquetes vacacionales, vuelos baratos
          internacionales, hoteles baratos y ofertas de viaje.
        </title>
        <meta
          name="description"
          content="¡Vuelos baratos internacionales, Hoteles baratos y ofertas, promociones en Paquetes vacacionales a los mejores destinos! Los mejores hoteles All Inclusive, Pagos en cuotas Fácil y Seguro online o por teléfono."
        />
        <meta
          name="keywords"
          content="hoteles, viajes on line, hoteles baratos, vuelos baratos internacionales, pasajes aereos, pasajes baratos, hospedaje, promociones en paquetes vacacionales, autos, seguro de viaje, cruceros, disney, alojamiento barato, viajes, promociones aereas, viajes en cuotas, excursiones"
        />
        <meta property="og:image" content={logo} />
        <meta property="og:site_name" content="mevuelo.com" />
        <meta property="og:title" content="mevuelo.com" />
        <meta
          property="og:description"
          content="¡Vuelos baratos internacionales, Hoteles baratos y ofertas, promociones en Paquetes vacacionales a los mejores destinos! Los mejores hoteles All Inclusive, Pagos en cuotas Fácil y Seguro online o por teléfono."
        />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content={ogLanguage} />
      </Helmet>

      <div className={cs(className)}>
        <div className="container">
          <img src={logo} alt="logo" className="logo" />

          <div className="content">
            <Text context="white" size="xlarge" className="margin">
              ¡Bienvenido!
            </Text>
          </div>
          <div className="content">
            <Text context="white" size="large" className="margin">
              ¿Desde dónde te vuelas?
            </Text>
            <Breakpoint medium up>
              <div className="buttons margin">
                <div className="option" onClick={goTo()}>
                  <img src={uruguay} alt="uruguay" className="flag" />
                  <Text context="white">URUGUAY</Text>
                </div>
                <div className="option" onClick={goTo('paraguay')}>
                  <img src={paraguay} alt="paraguay" className="flag" />
                  <Text context="white">PARAGUAY</Text>
                </div>
                <div className="option" onClick={goTo('bolivia')}>
                  <img src={bolivia} alt="Bolivia" className="flag" />
                  <Text context="white">BOLIVIA</Text>
                </div>
                <div className="option" onClick={goTo('colombia')}>
                  <img src={colombia} alt="Colombia" className="flag" />
                  <Text context="white">COLOMBIA</Text>
                </div>
                <div className="option" onClick={goTo('chile')}>
                  <img src={chile} alt="Chile" className="flag" />
                  <Text context="white">CHILE</Text>
                </div>
                <div className="option" onClick={goTo('mexico')}>
                  <img src={mexico} alt="Mexico" className="flag" />
                  <Text context="white">MÉXICO</Text>
                </div>
              </div>
            </Breakpoint>
            <Breakpoint small down>
              <div className="buttons column margin">
                <div className="option" onClick={goTo()}>
                  <img src={uruguay} alt="uruguay" className="flag" />
                  <Text context="white">URUGUAY</Text>
                </div>
                <div className="option" onClick={goTo('paraguay')}>
                  <img src={paraguay} alt="paraguay" className="flag" />
                  <Text context="white">PARAGUAY</Text>
                </div>
                <div className="option" onClick={goTo('bolivia')}>
                  <img src={bolivia} alt="Bolivia" className="flag" />
                  <Text context="white">BOLIVIA</Text>
                </div>
                <div className="option" onClick={goTo('colombia')}>
                  <img src={colombia} alt="Colombia" className="flag" />
                  <Text context="white">COLOMBIA</Text>
                </div>
                <div className="option" onClick={goTo('chile')}>
                  <img src={chile} alt="Chile" className="flag" />
                  <Text context="white">CHILE</Text>
                </div>
                <div className="option" onClick={goTo('mexico')}>
                  <img src={mexico} alt="Mexico" className="flag" />
                  <Text context="white">MÉXICO</Text>
                </div>
              </div>
            </Breakpoint>
          </div>
          <div className="content">
            <Text context="white" size="xlarge">
              Tenés ganas.
            </Text>
          </div>
        </div>
      </div>
    </>
  );
};

HomeMultiCountry.propTypes = {
  className: PropTypes.string.isRequired
};

export default styled(HomeMultiCountry)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 22px;
  min-height: 100vh;
  background-image: url(${backgroundMC});

  .container {
    background-color: rgb(49, 25, 75, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 667px;
    text-align: center;
    border-radius: 10px;
    justify-content: space-around;
    min-height: 80vh;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 667px;
    text-align: center;
  }

  .buttons {
    display: flex;
    position: relative;
    flex-flow: wrap;
    justify-content: center;
    align-items: center;

    &.column {
      flex-direction: column;
    }

    .option {
      min-width: 128px;
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;

      .flag {
        height: 47.3px;
        margin-bottom: 15px;
        margin-top: 5px;
      }
    }
  }

  .margin {
    margin-top: 15px;
  }

  .logo {
    max-width: 360px;
    margin-top: 15px;
  }
`;
