/**
 *
 * PaymentPromoUy
 *
 */

import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import NewText from 'Components/NewText';
import Button from 'Components/Button';
import Icon from 'Components/Icon';
import Portal from 'Components/Portal';
import Heading from 'Components/Heading';
import masterLogo from '../../Assets/images/masterLogo.png';
import visaLogo from '../../Assets/images/visaLogo.png';
import ocaLogo from '../../Assets/images/ocaLogo.png';
export const PaymentPromoUy = () => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    document.body.classList.toggle('non-scroll', showModal);
    return () => {
      document.body.classList.remove('non-scroll');
    };
  }, [showModal]);
  return (
    //URUGUAY
    <PromoBanner>
      <Container onClick={() => setShowModal(true)}>
        <Logo alt="oca-card" className="logo" src={ocaLogo} />
        <Logo alt="master-card" className="logo" src={masterLogo} />
        <Feecontainer>
          <NewText className="font-size"> hasta </NewText>
          <NewText
            className="fees"
            fontSize="36px"
            fontWeight="bold"
            color="orange">
            {' '}
            12{' '}
          </NewText>
          <NewText className="font-size"> cuotas sin intereses </NewText>
        </Feecontainer>
        <Logo alt="visa-card" className="logo" src={visaLogo} />
        <Feecontainer>
          <NewText className="font-size"> hasta </NewText>
          <NewText
            className="fees"
            fontSize="36px"
            fontWeight="bold"
            color="orange">
            {' '}
            6{' '}
          </NewText>
          <NewText className="font-size"> cuotas sin intereses </NewText>
        </Feecontainer>
        <Button className="ver-mas">
          <ButtonLabel>Ver más</ButtonLabel>
        </Button>
      </Container>
      {showModal ? (
        <TermsAndConditions onClose={() => setShowModal(false)} />
      ) : null}
    </PromoBanner>
  );
};
const PromoBanner = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.three};
    margin-top: ${theme.spacing.three};
  `}
`;

const ButtonLabel = styled.span`
  white-space: nowrap;
`;

const Logo = styled.img`
  ${({ theme }) => css`
	 max-height: 60px;
	 margin-right: ${theme.spacing.two};
	 @media screen and (max-width: 767px) {
		 max-width: 60px;
   `}
`;

const Container = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.three};
    margin-top: ${theme.spacing.three};
    background-color: ${theme.palette.grays.white};
    border-radius: ${theme.borderRadius.default};
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: ${theme.spacing.four};
    max-width: 1100px;
    margin: auto;
    .fees {
      padding-top: ${theme.spacing.one};
      padding-bottom: ${theme.spacing.one};
    }
    @media screen and (max-width: 767px) {
      position: relative;
      max-width: 95%;
      margin-bottom: ${theme.spacing.four};
      margin-top: ${theme.spacing.four};
      padding: ${theme.spacing.two};
      .ver-mas {
        display: none;
      }
    }
  `}
`;

const Feecontainer = styled.div`
  display: inline-grid;
  text-align: center;
  .font-size {
    @media screen and (max-width: 767px) {
      font-size: 11px;
    }
  }
`;

interface TermsAndConditionsProps {
  onClose: () => void;
}

const paragraphProps = {
  paragraph: true,
  type: 'small' as const
};
const TermsAndConditions = ({ onClose }: TermsAndConditionsProps) => (
  <Portal>
    <ModalOverlay>
      <Modal>
        <ModalCard>
          <Close onClick={onClose}>
            <Icon name="mv-cross" size={20} color="default" />
          </Close>
          <Heading type="h4">Condiciones de la promo Oca y MasterCard:</Heading>
          <NewText {...paragraphProps}>
            Hasta 12 pagos sin recargo. Promoción válida hasta el 30 de Junio de
            2023
          </NewText>
          <Heading type="h4">Condiciones Amex:</Heading>
          <NewText {...paragraphProps}>
            Hasta 6 pagos sin intereses con las Tarjetas American Express y
            Diners Club International emitidas en URUGUAY.
          </NewText>
          <NewText {...paragraphProps}>
            El fraccionamiento se realizará bajo solicitud del cliente para
            todos los productos comercializados por mevuelo.com Uruguay a través
            de la plataforma digital, canales telefónicos o presenciales.
            mevuelo.com se reserva el derecho de modificación de estas
            condiciones.
          </NewText>
        </ModalCard>
      </Modal>
    </ModalOverlay>
  </Portal>
);

const ModalOverlay = styled.div`
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.25);
  ${({ theme }) => css`
    z-index: ${theme.zIndex.modal};
  `}
`;

const Modal = styled.div`
  max-width: 800px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
`;

const ModalCard = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    padding: ${theme.spacing.four};
    display: flex;
    flex-direction: column;
    position: relative;
    h4 {
      margin-bottom: ${theme.spacing.two};
    }
    @media screen and (min-width: 767px) {
      margin: ${theme.spacing.four};
      border-radius: ${theme.borderRadius.default};
      min-height: 50vh;
    }
    @media screen and (max-width: 767px) {
      min-height: 100vh;
      padding-top: ${theme.spacing.twelve};
    }
  `}
`;

const Close = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: ${theme.spacing.two};
    right: ${theme.spacing.two};
    cursor: pointer;
  `}
`;
