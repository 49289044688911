import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import styles from './PackageAvailabilityCard.styles';
import { getFormatMoney } from 'Utils';

// TODO: solucionar el tema de Duracion y Fechas

import useBreakpoints from 'Utils/hooks/useBreakpoints';

import LazyImg from 'Components/LazyImg';
import Icon from 'Components/Icon';
import Text from 'Components/Text';
import { Link } from 'react-router-dom';
import { getBasePriceString } from 'Features/packages/helpers';
import Price from 'Components/Price';

const PackageAvailabilityCard = ({
  className,
  currency,
  price,
  title,
  image,
  icons,
  duration,
  lowerLocalPrice,
  //travelOn,
  url,
  basePrice,
  rating
}) => {
  const { largeDown, mediumDown } = useBreakpoints();
  const layout = cs({
    'one-column-container': mediumDown,
    'two-column-container': largeDown && !mediumDown,
    'three-column-container': !largeDown && !mediumDown
  });
  const [basePriceFirst, basePriceSecond] = getBasePriceString(basePrice);

  return (
    <div className={cs(className, layout)}>
      <Link to={url}>
        <div className="package-availability-card-container">
          <div className="package-availability-card-image">
            <LazyImg src={image} alt={title} className="img" height={259} />
            {rating ? (
              <div className="tag-holder">
                <Text context="white" weight="semi-bold">
                  {rating && rating.name}
                </Text>
              </div>
            ) : (
              ''
            )}
            <div className="icons-holder">
              {icons &&
                icons.map((icon, index) => (
                  <Icon key={index} name={icon.icon} color="white" size={20} />
                ))}
            </div>
          </div>
          <div className="title-holder">
            <Text paragraph weight="semi-bold" context="secondary" size="large">
              {title}
            </Text>
          </div>

          <div className="description-holder">
            <div>
              <Text size="small" paragraph>
                {duration}
              </Text>
              {/* <Text size="small" paragraph>
                {travelOn}
              </Text> */}
              {icons ? (
                <Text size="small" paragraph weight="semi-bold">
                  Incluye:
                </Text>
              ) : null}
              {icons &&
                icons.map((icon, index) => (
                  <Text key={index} size="small" paragraph>
                    {' '}
                    *{icon.name}{' '}
                  </Text>
                ))}
            </div>
            <div className="prices-holder">
              <Price currency={currency} price={price} />
              {lowerLocalPrice && (
                <Text
                  context="important"
                  alignment="right"
                  paragraph
                  size="small">
                  {getFormatMoney(
                    lowerLocalPrice.currency,
                    lowerLocalPrice.amount
                  )}
                </Text>
              )}
              <Text context="important" alignment="right" size="small">
                {basePriceFirst}
              </Text>
              <Text
                weight="semi-bold"
                context="important"
                alignment="right"
                size="small">
                {basePriceSecond}
              </Text>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

PackageAvailabilityCard.propTypes = {
  className: PropTypes.string,
  currency: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  duration: PropTypes.string,
  travelOn: PropTypes.string,
  icons: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired
    })
  ),
  lowerLocalPrice: PropTypes.object,
  color: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
  basePrice: PropTypes.number.isRequired,
  rating: PropTypes.shape({
    name: PropTypes.string,
    tagColor: PropTypes.string
  })
};

export default styles(PackageAvailabilityCard);
