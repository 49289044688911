/**
 *
 * PackageAvailabilityError
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cs from 'classnames';
import { useDispatch } from 'react-redux';
import * as COLORS from 'Constants/colors';
import Icon from 'Components/Icon';
import Text from 'Components/Text';
import Button from 'Components/Button';
import { onResetFilters, toggleModal } from '../../actions';
import useBreakpoints from 'Utils/hooks/useBreakpoints';

const ConnectedPackageAvailabilityError = props => {
  const dispatch = useDispatch();
  const actions = {
    resetFilters: () => {
      dispatch(onResetFilters());
    },
    showModal: () => {
      dispatch(toggleModal());
    }
  };

  return <StyledPackageAvailabilityError actions={actions} {...props} />;
};

const PackageAvailabilityError = ({ className, actions }) => {
  const { mediumDown } = useBreakpoints();
  return (
    <div className={cs(className, { 'medium-down': mediumDown })}>
      {!mediumDown && (
        <Icon name="mv-information-circle" color="white" size={44} />
      )}
      <div className="middle-text">
        <Text size="large" context="white">
          <b>SIN RESULTADOS:</b> No se encontraron resultados para los filtros
          seleccionados, limpiá los filtros o hacenos una consulta sobre el
          paquete que estas buscando.
        </Text>
      </div>
      <div className="buttons-holder">
        <Button
          context="purple"
          className="button"
          fullWidth
          onClick={actions.resetFilters}>
          Limpiar filtros
        </Button>
        <Button
          context="information"
          className="button"
          fullWidth
          onClick={actions.showModal}>
          Contáctanos
        </Button>
      </div>
    </div>
  );
};

PackageAvailabilityError.propTypes = {
  className: PropTypes.string.isRequired,
  actions: PropTypes.shape({
    resetFilters: PropTypes.func.isRequired,
    showModal: PropTypes.func.isRequired
  })
};

const StyledPackageAvailabilityError = styled(PackageAvailabilityError)`
  display: flex;
  max-width: 1100px;
  background-color: ${COLORS.important};
  border-radius: 4px;
  padding: 10px;

  .buttons-holder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-width: 180px;

    button:first-child {
      margin-bottom: 10px;
    }
  }

  .middle-text {
    padding: 10px;
    display: flex;
    align-items: center;
  }

  &.medium-down {
    flex-direction: column;
    align-items: center;

    .buttons-holder {
      .button {
        margin-bottom: 5px;
      }
    }
  }
`;

export default ConnectedPackageAvailabilityError;
export { StyledPackageAvailabilityError as PackageAvailabilityError };
